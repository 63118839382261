import { Container, Form, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown/with-html";
import NewsletterForm from "../forms/newsletter-form-footer";
//import CookieConsent from "react-cookie-consent";
import CookieConsent from "../CookieConsent/CookieConsent"
import { Twitter, Facebook, Linkedin, Instagram, StarBerry, TikTok } from '../icon';
import $ from 'jquery'
import PopularSearch from "../popular-search-static"
import PopularSearchDynamic from "../popular-search-dynamic"
import PopularSearchDetails from "../popular-search-details"
import PopularSearchStaticDetails from "../popular-search-static-details"
import { useLocation, useMatch } from "@reach/router"
import FloatingPointSection from '../Footer/FloatingPoint';
import StickyCtaSection from "../StickyCta/StickyCta";
import GetURL from "../get-url";
import OurOffice from '../our-office';

const Footer = (props) => {
  var developmentTitle = props.propertydetails && props.propertydetails[0].title && props.propertydetails[0].title;
  var d = new Date();
  var n = d.getFullYear();
  const checkmanchester = useLocation();
  const manchester = useMatch("/manchester/")
  let addresstown = props.propertyaddress;
  let url = process.env.GATSBY_SITE_URL;
  const investor = useMatch("/investments/")
  const investorpath = investor && investor.path;
  const investment = useMatch("property-services/investors/")
  const investmentpath = investment && investment.path;
  const development = useMatch("/property/developments/new-homes/for-sale/in-london-and-manchester/")
  const developmentpath = development && development.path;
  const investorguide = useMatch("/investments/investors-guides/")
  const investorguidepath = investorguide && investorguide.path;

  const manchesterguidedetail = useMatch("/about-us/areas-we-cover/manchester-area-guides/")
  const manchesterdetail = useMatch("/about-us/areas-we-cover/manchester-area-guides/:item/")
  const manchesteroffice = useMatch("/contact/our-offices/manchester-offices/")
  const manchesterofficedetail = useMatch("/contact/our-offices/manchester-offices/:item/")
  const manchesterliverpool = useMatch("/investments/investors-guides/liverpool-investment-guide/")
  const contactofficemanchester = useMatch("/contact/general-enquiry/?contactoffice=manchester-property-investments")
  const manchesterinvestorguide = useMatch("/investments/investors-guides/manchester-investment-guide/")
  const propertyservicesmanchester = useMatch("/property-services/property-management-manchester/")
  const rentManchesterSearch = useMatch("/property/to-rent/in-manchester/")
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''

  var manchesterpage = ''
  if (manchester != null || manchesterguidedetail != null || manchesterdetail != null || manchesteroffice != null ||
    manchesterofficedetail != null
  ) {
    manchesterpage = 'yes';
  }
  var whatsapplink = ''
  var telegramlink = ''
  var phonenumberlink = ''
  if (manchester != null || manchesterguidedetail != null || manchesterdetail != null || manchesteroffice != null ||
    manchesterofficedetail != null || investorpath != null || investmentpath != null || developmentpath != null || investorguidepath != null
    || manchesterliverpool != null || contactofficemanchester != null || manchesterinvestorguide != null || propertyservicesmanchester != null || rentManchesterSearch != null
  ) {
    whatsapplink = 'yes';
    telegramlink = 'yes';
    phonenumberlink = 'yes';
  }
  
  const [render, setRender] = useState(false)

  useEffect(() => {

    setRender(true)

    $(".open-popular-search").click(function (event) {
      $(".open-popular-search").toggleClass("searchopen");
      $(".popular-searchlist").slideToggle();
    });
    // 
    $(".footer_scroll_top").click(function () {
      $("html, body").scrollTop(0);
    })
    // 
    function isInViewport(el) {
      let top = el.offsetTop;
      let left = el.offsetLeft;
      let width = el.offsetWidth;
      let height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top < (window.pageYOffset + window.innerHeight) &&
        left < (window.pageXOffset + window.innerWidth) &&
        (top + height) > window.pageYOffset &&
        (left + width) > window.pageXOffset
      );

    }

    const box = document.querySelector('.footersec');
    const img = document.querySelector('.result-map');
    document.addEventListener('scroll', handleScroll, {
      passive: true
    });

    function handleScroll() {

      if (isInViewport(box)) {
        /** Here one can write animate.css class for animation **/

        box.classList.add('animate__slideInUp', 'animate__slower');

      } else {

        box.classList.remove('animate__slideInUp', 'animate__slower');

      }

      try {

        if (isInViewport(box) && img !== null) {
          console.log("hello world ")
          /** Here one can write animate.css class for animation **/
          img.classList.add('result-map-scroll');

        } else if (img !== null) {
          img.classList.remove('result-map-scroll');
        }

      } catch (e) {

      }



    }
    return () => {
      document.addEventListener('scroll', handleScroll);
    }

  }, [])

  return <React.Fragment>
    {!propertyservicesmanchester && <OurOffice />}

    <footer className="footer">
     <CookieConsent />
     
      {/* <Container className="footersec animate__animated"> */}
      <Container className="footersec">
     
        <div className="share-media">
          <div className="d-md-flex justify-content-md-between align-items-md-center">
            <div className="f-search">
              <a  className='open-popular-search search-link' href="javascript:;">Popular Property Searches</a>
            </div>
            <ul className="social-media d-flex align-items-center">
              <li>
                <a href={manchesterpage || investorpath || investmentpath || developmentpath || investorguidepath ? 'https://www.facebook.com/Orlandoreidmanchester' : props.footerDetails.Facebook_Link} target="_blank">
                  <Facebook />
                </a>
              </li>
              <li>
                <a href={manchesterpage || investorpath || investmentpath || developmentpath || investorguidepath ? 'https://www.instagram.com/orlandoreidmanchester/?hl=en' : props.footerDetails.Instagram_Link} target="_blank">
                  <Instagram />
                </a>
              </li>
              <li>
                <a href={investorpath || investmentpath || developmentpath || investorguidepath ? 'https://www.linkedin.com/company/orlando-reid/' : props.footerDetails.Linkedin_Link} target="_blank">
                  <Linkedin />
                </a>
              </li>
              <li>
                <a href={investorpath || investmentpath || developmentpath || investorguidepath ? "https://twitter.com/OrlandoReidUK" : props.footerDetails.Twitter_Link} target="_blank">
                  <Twitter />
                </a>
              </li>
              <li>
                <a href={"https://www.tiktok.com/@orlandoreiduk"} target="_blank">
                  <TikTok />
                </a>
              </li>
            </ul>
          </div>
          <section className="popular-searchlist">
            {props.popularSearch == 'propertydetails' &&
              <PopularSearchDetails propertydetails={props.propertydetails} />
            }
            {props.popularSearch == 'staticdetails' ? (props.area == 'manchester-property-investments' ?
              <PopularSearch popularSearch='Popular_Search_Static_Manchester' />
              : <PopularSearchStaticDetails office={props.office} area={props.area} guides={props.guides} />)
              : ''
            }
            {props.popularSearch == 'propertyresults' ?
              <PopularSearchDynamic searchtype={props.searchtype} areaName={props.areaName} />
              : <PopularSearch popularSearch={props.popularSearch} />
            }
          </section>
        </div>


        {/* footer primary start */}
        <div className="footer-primary d-xl-flex justify-content-xl-between">
          <Form className="newsletter">
            <h3>
              {props.footerDetails.Footer_Newsletter_Title}
            </h3>
            <NewsletterForm />

          </Form>
          <div className="branch-details">
            <ReactMarkdown source={props.footerDetails.Footer_Office_Address} escapeHtml={false} />
          </div>
        </div>
        {/* footer primary end*/}
        {/* footer secondary start*/}
        {/* <div className="footer-secondary d-md-flex align-items-md-start justify-content-md-between">
          <div className="copy-right">
            <ul className="d-flex">
              <li>
                <Link to="/terms-and-conditions/">terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy/">Privacy policy</Link>
              </li>
              <li>
                <Link to="/cookies-policy/">Cookie policy</Link>
              </li>
              <li>
                <Link to="/sitemap/">Sitemap</Link>
              </li>

            </ul>
            <span>&copy; {n} Orlando Reid. All Rights Reserved.</span>
          </div>
        </div> */}

        <Row className='footer_secondary'>
          <Col>
            <div className="copyright-block">
              <p className="footer-link">
                {props.footerDetails.Footer_Links.length > 0 && (
                  props.footerDetails.Footer_Links.map((f)=>(
                    <GetURL label={f.label} URL={f.Menu_item.URL} Secondary_URL={f.Menu_item.Secondary_URL} />
                  ))
                )}
                
                
                <a href="javascript:void(0);" id="open_preferences_center">Update cookies preferences</a>
              </p>

              <p>&copy; {n} Orlando Reid. All Rights Reserved.<br />
                <a target="_blank" href="https://starberry.tv/" className="siteby d-md-flex align-items-center">
                  <span>Site by <span className="d-md-none">Starberry</span></span>
                  <StarBerry />
                </a>
              </p>
            </div>
          </Col>

          {props.footerDetails.Clients.length > 0 &&
            <Col>
              <div className="logo-block">
                {props.footerDetails.Clients.map((c)=>(
                  <a href={c.Client_Url} target="_blank" >
                    <img className='p-2' loading='lazy' src={`${process.env.GATSBY_SITE_URL}${c.Client_Image.publicURL}`} alt={c.Client}/>
                  </a>
                ))}
              </div>
            </Col>
          }
        </Row>
        {/* footer secondary end*/}
      </Container>
      <FloatingPointSection telegramlink={telegramlink} whatsapplink={whatsapplink} phonenumberlink={phonenumberlink}  storagearea={props.storagearea} addresstown={addresstown} developmentTitle={developmentTitle} />
       <StickyCtaSection phonenumberlink={phonenumberlink}/>
      {/* <CookieConsent
        disableStyles={true} 
        containerClasses="cookie-popup d-lg-flex d-block"
        contentClasses=""
        buttonClasses="btn btn-link gotit"
        buttonText="Accept Cookies">

        <p className="mb-0 text-center">We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/cookies-policy/">Cookie Policy</Link>.</p>
      </CookieConsent> */}
      

      {props.showofficeschema == "Yes" && render &&
        <Helmet>
          {/* Added Implement the breadcrumb list schema start */}
          <script type="application/ld+json">{`{
          "@context": "https://schema.org/", 
          "@type": "BreadcrumbList", 
          "itemListElement": [{
            "@type": "ListItem", 
            "position": 1, 
            "name": "Home",
            "item": "https://www.orlandoreid.co.uk/"  
          },{
            "@type": "ListItem", 
            "position": 2, 
            "name": "Contact",
            "item": "https://www.orlandoreid.co.uk/contact/" 
          },{
            "@type": "ListItem", 
            "position": 3, 
            "name": "Our Offices",
            "item": "https://www.orlandoreid.co.uk/contact/our-office/" 
          },{
            "@type": "ListItem", 
            "position": 4, 
            "name": "London Offices",
            "item": "https://www.orlandoreid.co.uk/contact/our-office/london-offices/" 
          },{
            "@type": "ListItem", 
            "position": 5, 
            "name": "${props.title}",
            "item": "${pageurl}" 
          }]
        }`}</script>
          {/* Added Implement the breadcrumb list schema end */}
        </Helmet>
      }
    </footer>
  </React.Fragment>
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
