import React, { useState, useEffect } from "react"

const OurOffice = () => {
  return (
    <div className="insight-content-block our_office_title">
      <center>
        <span className="sub-title">Our Offices </span>
        <p> <a href="/contact/our-offices/london-offices/clapham-estate-agents/">Clapham</a>     |    <a href="/contact/our-offices/london-offices/battersea-estate-agents/"> Battersea  </a>  |   <a href="/contact/our-offices/london-offices/mayfair/"> Mayfair  </a>  |   <a href="/contact/our-offices/nottingham-property-investments-and-property-management/"> Nottingham   </a> |   <a href="/contact/our-offices/riyadh-property-investment-uk/"> Riyadh  </a>  |   <a href="/contact/our-offices/manchester-offices/manchester-property-investments/"> Manchester</a></p>
      </center>
    </div>
  )
}
export default OurOffice
