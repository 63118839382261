import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import $ from 'jquery'
const NewsSection = (props) => {
    return (<React.Fragment>
        {props?.propertydetails?.map((property) => {
        var typeText = ''
        var URL = ''
        var ptypeURL= '/type-'+property.building
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }  
        if(property.search_type == 'sales') {
            typeText= 'for Sale'
            URL          = '/property/for-sale/'
        }
        if(property.search_type == 'lettings') {
            typeText= 'to Rent'
            URL          = '/property/to-rent/'
        }
        if(property.department == 'new_homes' && property.search_type == 'sales') {
            URL          = '/property/new-homes/for-sale/'
        }
        if(property.department == 'new_homes' && property.search_type == 'lettings') {
            URL          = '/property/new-homes/to-rent/'
        }
        if(property.department == 'new_developments' && property.search_type == 'sales') {
            URL          = '/property/new-developments/for-sale/'
        }
        if(property.department == 'new_developments' && property.search_type == 'lettings') {
            URL          = '/property/new-developments/to-rent/'
        }
        if(property.department == 'help_to_buy') {
            URL          = '/property/help-to-buy/'
        }
        let townURL = ''
        let townName = ''
        townURL = property?.address?.town
        townName = property?.address?.town
        if(typeof townURL === "undefined" || townURL === null){
            townURL = 'london-and-manchester'
            townName = 'London'
        } else {
            townURL = townURL.toLowerCase()
            townURL = townURL.replace(" ","-")
        }
        
        return(
        <Row>
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-${townURL}/`}>Properties {typeText} in {townName}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${townURL}/type-houses/`}>Houses {typeText} in {townName}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${townURL}/type-flats/`}>Flats {typeText} in {townName}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${townURL}/type-apartments/`}>Apartment {typeText} in {townName}</Link></li>
            {property?.city === "Manchester" ?
                               <>
                                  <li><Link className="footer_scroll_top" to="/contact/our-offices/manchester-offices/">Manchester Letting Agents</Link></li>
                                  <li><Link className="footer_scroll_top" to="/contact/our-offices/manchester-offices/manchester-property-investments/">Manchester Property Investments</Link></li>
                                </> : 
                                <>
                                <li><Link className="footer_scroll_top" to="/contact/our-offices/london-offices/clapham-estate-agents/">Clapham Estate Agents</Link></li>
                                <li><Link className="footer_scroll_top" to="/contact/our-offices/london-offices/battersea-estate-agents/">Battersea Estate Agents</Link></li>
                              </>
            
             }
            </ul>
            </Col>
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to="/about-us/areas-we-cover/london-area-guides/">London Area Guides</Link></li>
            <li><Link className="footer_scroll_top" to="/property-valuation/">Property Valuation London</Link></li>
            <li><Link className="footer_scroll_top" to="/contact/our-offices/">London Estate Agents</Link></li>           
            </ul>
            </Col>
            <Col md={6} lg={3}>
                <ul>
                <li><Link className="footer_scroll_top"  to={`${URL}in-london${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in London</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-battersea${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Battersea</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-clapham${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Clapham</Link></li>
                </ul>
            </Col>
            <Col md={6} lg={3}>
            <ul>
                <li><Link className="footer_scroll_top" to={`${URL}in-brixton${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Brixton</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-balham${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Balham</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-nine-elms${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Nine Elms</Link></li>
                </ul>
            </Col>
        </Row>
        )
        })}
    </React.Fragment>)

}

export default NewsSection;